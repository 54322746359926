import React, {
  createContext, useContext, useEffect, useState
} from 'react';

import getLocalizedString from './getLocalizedString';

export const I18nContext = createContext();

export const I18nProvider = ({ children }) => {
  const [language, setLanguage] = useState('');

  const useTranslationFn = (pageContext) => {
    const effectiveLanguage = language || pageContext.language;
    const t = (key) => getLocalizedString(effectiveLanguage, key);
    const languageIsSet = !!language;

    /**
     * `language` is the language that has been explicitly set. If it is not set, it is an empty string.
     * `languageIsSet` is a boolean that indicates whether the language has been set.
     * `effectiveLanguage` is the current language that is actually used for translations, so either the same
     *                     as `language` if it is set, or the fallback language.
     * `setLanguage` is a function that can be used to set `language`.
     * `t` is the translation function.
     */
    return {
      language, languageIsSet, effectiveLanguage, setLanguage, t,
    };
  };

  useEffect(() => {
    if (language) {
      localStorage.setItem('language', language);
    }
  }, [language]);

  return (
    <I18nContext.Provider value={{ useTranslationFn }}>
      {children}
    </I18nContext.Provider>
  );
};

export function useTranslation(pageContext) {
  return useContext(I18nContext).useTranslationFn(pageContext);
}

export default I18nProvider;
