import en from './locales/en.json';
import fr from './locales/fr.json';

function flatten(obj, parentKey = '') {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object') {
      Object.assign(flattened, flatten(obj[key], newKey));
    } else {
      flattened[newKey] = obj[key];
    }
  });
  return flattened;
}

const locales = {
  en: flatten(en),
  fr: flatten(fr),
};

export default function getLocalizedString(language, key) {
  if (language) {
    return locales[language][key] || '';
  }
  return '';
}
