exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog-en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-fr-js": () => import("./../../../src/pages/blog-fr.js" /* webpackChunkName: "component---src-pages-blog-fr-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-genealogy-en-js": () => import("./../../../src/pages/genealogy-en.js" /* webpackChunkName: "component---src-pages-genealogy-en-js" */),
  "component---src-pages-genealogy-fr-js": () => import("./../../../src/pages/genealogy-fr.js" /* webpackChunkName: "component---src-pages-genealogy-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiel-pedagogique-js": () => import("./../../../src/pages/materiel-pedagogique.js" /* webpackChunkName: "component---src-pages-materiel-pedagogique-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-genealogy-page-js": () => import("./../../../src/templates/genealogy-page.js" /* webpackChunkName: "component---src-templates-genealogy-page-js" */),
  "component---src-templates-generic-markdown-page-js": () => import("./../../../src/templates/generic-markdown-page.js" /* webpackChunkName: "component---src-templates-generic-markdown-page-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-teaching-material-document-js": () => import("./../../../src/templates/teaching-material-document.js" /* webpackChunkName: "component---src-templates-teaching-material-document-js" */)
}

