/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ThemeProvider } from './src/theme/ThemeProvider';
import { I18nProvider } from './src/i18n/I18nProvider';

// eslint-disable-next-line
export function shouldUpdateScroll() {
  const body = document.getElementsByTagName('body')[0];
  const tableOfContents = document.getElementsByClassName('table-of-contents');
  
  // Make sure that scroll position works as expected when navigating between pages,
  // while also allowing anchor links in a table of contents to scroll to the correct position.
  if (tableOfContents.length > 0) {
    window.scrollTo(0, 0);
    body.scrollTop = 0;
    return false;
  } else {
    return true;
  }
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider><I18nProvider>{element}</I18nProvider></ThemeProvider>
);
